import React from 'react';
import Accordion from './Accordion';
import './Blog.css';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const Blog = () => {
    const { language } = useLanguage(); // Get the current language from context

    // Retrieve the translated content based on selected language
    const translation = translations[language].blog;

    return (
        <section id="blog" className='blog'>
            <h2 className="blog-title">{translation.title}</h2>
            <div className="blog-list">
                {translation.posts.map((post, index) => (
                    <Accordion key={index} title={post.title}>
                        <p>{post.description}</p>
                    </Accordion>
                ))}
            </div>
        </section>
    );
};

export default Blog;
