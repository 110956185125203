import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faLeaf, faUsers, faHeart } from '@fortawesome/free-solid-svg-icons';
import './Affiliations.css';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const Affiliations = () => {
    const { language } = useLanguage(); // Get the current language from context
    // Retrieve the translated content based on selected language
    const translation = translations[language].affiliations;
    return (
        <section id="affiliations" className='affiliations'>
            <h2 className="affiliations-title">{translation.title}</h2>
            <p>{translation.description}</p>
            <ul className="affiliations-list">
                {translation.items.map((item, index) => (
                    <AffiliationItem
                        key={index}
                        title={item.title}
                        description={item.description}
                        icon={
                            index === 0 ? <FontAwesomeIcon icon={faBalanceScale} /> :
                                index === 1 ? <FontAwesomeIcon icon={faUsers} /> :
                                    index === 2 ? <FontAwesomeIcon icon={faLeaf} /> :
                                        <FontAwesomeIcon icon={faHeart} />
                        }
                    />
                ))}
            </ul>
        </section>
    );
};

const AffiliationItem = ({ title, description, icon }) => (
    <li className="affiliation-item">
        <div className="affiliation-icon">
            {icon}
        </div>
        <div className="affiliation-text">
            <strong>{title}</strong> - {description}
        </div>
    </li>
);

export default Affiliations;
