import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faBriefcase, faUsers, faHandcuffs, faScroll } from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

// Tekil hizmet öğesi bileşeni
const ServiceItem = ({ title, description, icon }) => (
    <li className="service-item">
        {icon && <div className="service-icon">{icon}</div>}
        <div className="service-content">
            <h3 className="service-title">{title}</h3>
            <p className="service-description">{description}</p>
        </div>
    </li>
);

const Services = () => {
    const { language } = useLanguage(); // Get the current language from context

    // Retrieve the translated content based on selected language
    const servicesData = translations[language].services;


    return (
        <section id="services" className='services'>
            <h2 className="services-title">{servicesData.title}</h2>
            <ul className="services-list">
                {servicesData.items.map((service, index) => (
                    <ServiceItem
                        key={index}
                        title={service.title}
                        description={service.description}
                        icon={
                            index === 0 ? <FontAwesomeIcon icon={faGavel} /> :
                                index === 1 ? <FontAwesomeIcon icon={faBriefcase} /> :
                                    index === 2 ? <FontAwesomeIcon icon={faUsers} /> :
                                        index === 3 ? <FontAwesomeIcon icon={faHandcuffs} /> :
                                            <FontAwesomeIcon icon={faScroll} />
                        }
                    />
                ))}
            </ul>
        </section>
    );
};

export default Services;