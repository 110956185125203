import React from 'react';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useLanguage } from '../language/LanguageContext';
import translations from '../language/lang';

const Footer = () => {
    const { language, changeLanguage } = useLanguage(); // changeLanguage fonksiyonunu burada kullanıyoruz

    // Dil değişikliği işlevi
    const handleLanguageChange = (lang) => {
        changeLanguage(lang); // changeLanguage fonksiyonu ile dil değişiyor
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Sosyal Medya İkonları */}
                <div className="social-icons">
                    <a href="https://www.instagram.com/av.veli.celik?utm_source=qr&igsh=c213cmV4OXZsNWI0" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaFacebookF />
                    </a>
                    <a href="https://x.com/av_veli_celik?t=PYamIUtJS0ThzXTHDIjNRA&s=09" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaTwitter />
                    </a>
                    <a href="https://www.linkedin.com/in/veli-%C3%A7elik-077963268/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaLinkedinIn />
                    </a>
                    <a href="https://www.instagram.com/av.veli.celik?utm_source=qr&igsh=c213cmV4OXZsNWI0" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaInstagram />
                    </a>
                    <a href="mailto:info@avvelicelik.com" className="social-icon">
                        <FaEnvelope />
                    </a>
                </div>
            </div>

            {/* Dil Seçeneği */}
            <div className="language-selector">
                <button
                    className={`language-button ${language === 'tr' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('tr')}
                >
                    Türkçe
                </button>
                <button
                    className={`language-button ${language === 'en' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                >
                    English
                </button>                <button
                    className={`language-button ${language === 'ku' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('ku')}
                >
                    Kurdi
                </button>
                <button
                    className={`language-button ${language === 'zz' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('zz')}
                >
                    Zaza
                </button>
            </div>

            <p className="footer-text">&copy;{translations[language].footer.text}</p>
        </footer>
    );
};

export default Footer;
