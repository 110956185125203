import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com';
import {
    TextField,
    MenuItem,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Modal,
    Box
} from "@mui/material";
import "./DanismanlikForm.css";
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const DanismanlikForm = ({ handleClose }) => {
    const { language } = useLanguage(); // Aktif dil bilgisini alıyoruz
    const translation = translations[language].danismanlikForm; // Aktif dilin danismanlikForm içeriğini alıyoruz

    const [formData, setFormData] = useState({
        category: "",
        subCategory: "",
        description: "",
        name: "",
        email: "",
        phone: "",
        appointment: false,
        privacyAgreement: false,
    });

    const [subCategories, setSubCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const form = useRef();

    // Kategoriler, artık dil desteğiyle dinamik hale geliyor
    const categories = translation.categories;

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setFormData({ ...formData, category: selectedCategory, subCategory: "" });
        setSubCategories(categories[selectedCategory] || []);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        const templateParams = {
            from_name: formData.name,
            category: formData.category,
            subCategory: formData.subCategory,
            description: formData.description,
            phone: formData.phone,
            email: formData.email,
            appointment: formData.appointment ? 'Evet' : 'Hayır',
            message: formData.description,
            lawyer: "Av. Veli Çelik",
            team: "ErosBT Geliştirici Ekibi"
        };

        emailjs.send('service_hd1ucan', 'template_26vkk53', templateParams, '7Fw7Gg1u-uea-vPys')
            .then((result) => {
                console.log(result.text);
                setLoading(false);
                setOpenModal(true);
            }, (error) => {
                console.log(error.text);
                setLoading(false);
                alert(translation.errorMessage);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.privacyAgreement) {
            alert(translation.privacyAlert);
            return;
        }
        sendEmail(e);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="form-container">
            <form ref={form} onSubmit={handleSubmit}>
                <Typography className="form-title">{translation.title}</Typography>

                <TextField
                    label={translation.categoryLabel}
                    name="category"
                    select
                    fullWidth
                    value={formData.category}
                    onChange={handleCategoryChange}
                    className="form-field"
                >
                    {Object.keys(categories).map((category) => (
                        <MenuItem key={category} value={category}>
                            {category}
                        </MenuItem>
                    ))}
                </TextField>

                {subCategories.length > 0 && (
                    <TextField
                        label={translation.subCategoryLabel}
                        name="subCategory"
                        select
                        fullWidth
                        value={formData.subCategory}
                        onChange={handleChange}
                        className="form-field"
                    >
                        {subCategories.map((subCategory) => (
                            <MenuItem key={subCategory} value={subCategory}>
                                {subCategory}
                            </MenuItem>
                        ))}
                    </TextField>
                )}

                <TextField
                    label={translation.descriptionLabel}
                    name="description"
                    multiline
                    rows={4}
                    fullWidth
                    value={formData.description}
                    onChange={handleChange}
                    className="form-field"
                />

                <TextField
                    label={translation.nameLabel}
                    name="name"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    className="form-field"
                />
                <TextField
                    label={translation.emailLabel}
                    name="email"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    className="form-field"
                />
                <TextField
                    label={translation.phoneLabel}
                    name="phone"
                    type="tel"
                    fullWidth
                    value={formData.phone}
                    onChange={handleChange}
                    className="form-field"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            name="appointment"
                            checked={formData.appointment}
                            onChange={handleChange}
                        />
                    }
                    label={translation.appointmentLabel}
                    className="form-checkbox-label"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            name="privacyAgreement"
                            checked={formData.privacyAgreement}
                            onChange={handleChange}
                            required
                        />
                    }
                    label={translation.privacyAgreementLabel}
                    className="form-checkbox-label"
                />

                <Button type="submit" variant="contained" fullWidth className="form-button">
                    {loading ? <CircularProgress size={24} /> : translation.submitButton}
                </Button>
            </form>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <Box className="modal-box">
                    <Typography id="success-modal-title" variant="h6" component="h2">
                        {translation.successModalTitle}
                    </Typography>
                    <Typography id="success-modal-description" sx={{ mt: 2 }}>
                        {translation.successModalDescription}
                    </Typography>
                    <Button onClick={handleCloseModal} variant="contained" color="primary" sx={{ mt: 2 }}>
                        {translation.modalCloseButton}
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default DanismanlikForm;
