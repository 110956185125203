import React from 'react';
import './Home.css';
import Herosection from '../components/Herosection';
import Parallax from '../components/Parallax';
import image from '../assets/justice2.svg'; // Ensure the background image is relevant, such as a law office setting
import About from '../components/About';
import Services from '../components/Services';
import Blog from '../components/Blog';
import Affiliations from '../components/Affiliations';
import Contact from '../components/Contact';
import FAQ from '../components/FAQ.';

const Home = () => (
    <main className="home">
        <Herosection /> {/* Hero section with a welcoming message */}
        <Parallax imageUrl={image}>
            <About />
            <Services />
            <Blog />
            <Affiliations />
            <FAQ />
            <Contact />
        </Parallax>
    </main>
);

export default Home;
