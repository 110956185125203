import React from 'react';
import './Parallax.css';

const Parallax = ({ imageUrl, children }) => {
    return (
        <section className="parallax" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="parallax-content">
                {children}
            </div>
        </section>
    );
};

export default Parallax;
