const translations = {
    tr: {
        // Navbar
        navbar: {
            home: "Anasayfa",
            about: "Hakkında",
            services: "Hizmetler",
            blog: "Blog",
            contact: "İletişim",
            title: "Av. Veli ÇELİK",
        },
        // Herosection
        herosection: {
            title: "Hoş Geldiniz",
            description: "Adaletin peşinden gidiyoruz. Profesyonel hukuki danışmanlık için hemen bizimle iletişime geçin.",
            button: "Randevu Al",
        },
        // About
        about: {
            title: "Hakkında",
            description1: "Avukat Veli Çelik, hukuk alanında yıllara dayanan deneyimi ve derin uzmanlığı ile tanınan bir hukuk profesyonelidir.",
            description2: "Türkiye’nin önde gelen hukuk bürolarında edindiği tecrübeler ve çeşitli alanlardaki başarılı dava sonuçlarıyla kendisini kanıtlamıştır.",
            description3: "İnsan hakları, ticaret hukuku, aile hukuku gibi pek çok alanda derinlemesine bilgi sahibi olan Avukat Veli Çelik, müvekkillerine her zaman adil ve etkin bir hukuki temsil sunmaktadır.",
        },
        // Services
        services: {
            title: "Hizmetler",
            items: [
                {
                    title: "İnsan Hakları Hukuku",
                    description: "Adaletin tecellisi ve birey haklarının korunması adına mücadele. Özellikle ayrımcılık, ifade özgürlüğü ve haksız tutuklamalara karşı yoğun bir çalışma yürütülmektedir.",
                },
                {
                    title: "Ticaret Hukuku",
                    description: "Şirketlerin yasal danışmanlık ve ticari uyuşmazlık çözümü. Müşterilerimize şirket kurulumundan sözleşme düzenlemeye kadar her türlü hizmeti sunmaktayız.",
                },
                {
                    title: "Aile Hukuku",
                    description: "Boşanma, nafaka, velayet gibi aile içi davalarda profesyonel hizmet. Aile içi şiddet, miras davaları ve mal paylaşımı gibi karmaşık durumlarda müvekkillerimize çözüm sunuyoruz.",
                },
                {
                    title: "Ceza Hukuku",
                    description: "Suçluluk durumunda savunma ve mağdur haklarının savunulması. Suçluluğu ispatlanmış sanıklara savunma yaparken mağdur haklarının korunması adına da savunuculuk yapıyoruz.",
                },
                {
                    title: "Miras Hukuku",
                    description: "Miras paylaşımı, vasiyetname düzenlemeleri ve davalarına çözüm önerileri. Mirasla ilgili çıkabilecek her türlü uyuşmazlık için uzman çözüm önerileri sunuyoruz.",
                },
            ],
        },
        // Blog
        blog: {
            title: "Blog",
            posts: [
                {
                    title: "Son Değişiklikler: Ceza Hukuku Reformu",
                    description: "Türkiye'deki ceza hukuku yasalarındaki önemli değişiklikler ve bu değişikliklerin bireylerin hakları üzerindeki etkileri üzerine bir değerlendirme.",
                },
                {
                    title: "Boşanma Davalarında Dikkat Edilmesi Gereken 5 Şey",
                    description: "Boşanma davalarında dikkat edilmesi gereken temel unsurlar ve doğru adımlar atarak sürecin nasıl yönetilebileceği üzerine bir rehber.",
                },
                {
                    title: "İnsan Hakları ve Hukuki Koruma",
                    description: "İnsan hakları ihlalleri ve bu ihlallerin hukuki boyutları hakkında bir inceleme.",
                },
            ],
        },
        // Affiliations
        affiliations: {
            title: "Birlikte Mücadele Ettiğimiz Örgütler/Sendikalar",
            description: "Avukat Veli Çelik, aşağıdaki sosyal ve çevre örgütlerine üyedir ve bu alanlarda aktif çalışmalar yürütmektedir:",
            items: [
                {
                    title: "Türk Barolar Birliği",
                    description: "Hukuk alanındaki en önemli derneklerden biri.",
                },
                {
                    title: "Adalet Platformu",
                    description: "Adaletin sağlanması ve güçlendirilmesi için çalışmalar yapan bir topluluk.",
                },
                {
                    title: "Çevre ve Doğa Derneği",
                    description: "Doğal çevrenin korunması için yasa ve politika önerileri üzerinde çalışmalar.",
                },
                {
                    title: "Kadın Hakları Platformu",
                    description: "Kadınların hukuki hakları ve toplumsal cinsiyet eşitliği üzerine çalışmalar.",
                },
            ],
        },
        // FAQ
        faq: {
            title: "Sıklıkla Sorulan Sorular (SSS)",
            items: [
                {
                    question: "Hukuki Danışmanlık Hizmetleri Nelerdir?",
                    answer: "Hukuki danışmanlık, bir avukatın yasal haklarınız, yükümlülükleriniz ve prosedürler hakkında size bilgi vermesi, gerektiğinde sizi yönlendirmesi anlamına gelir. Bu hizmet, boşanma, ticaret, miras, ceza davaları gibi farklı hukuki alanlarda olabilir. Avukatımız, durumunuza en uygun çözüm yollarını sunarak sizi en doğru şekilde yönlendirecektir.",
                },
                {
                    question: "Bir Davada Avukata Ne Zaman İhtiyaç Duyulur?",
                    answer: "Birçok dava, özellikle karmaşık hukuki konular içeren davalar için bir avukatın desteği gereklidir. Avukat, dava sürecinde haklarınızı savunur, yasal belgelerinizi hazırlar ve sizi mahkeme sürecinde temsil eder. Ayrıca, mülkiyet, aile, ticaret veya ceza hukuku gibi alanlarda da uzman bir avukattan danışmanlık almak önemli olabilir.",
                },
                {
                    question: "Avukat Ücretleri Nasıl Hesaplanır?",
                    answer: "Avukatlık ücretleri, dava türüne, avukatın deneyimine ve vereceği hizmetin kapsamına bağlı olarak değişir. Çoğu avukat, danışmanlık ve temsil hizmetlerini belirli bir ücret karşılığında sunar. Ücretlendirme genellikle sabit ücret, saatlik ücret veya başarı bazlı ücretlendirme olarak belirlenebilir. Başlangıçta ücret hakkında bilgi almak için avukatla bir görüşme yapmanızı öneririz.",
                },
                {
                    question: "Boşanma Davasında Avukata Ne Zaman İhtiyaç Duyulur?",
                    answer: "Boşanma süreci yasal ve duygusal açıdan karmaşık olabilir. Mal paylaşımı, çocukların velayeti gibi önemli konularda profesyonel bir avukat, haklarınızı savunmanıza ve süreci en sağlıklı şekilde yürütmenize yardımcı olur. Boşanma davalarında avukat, her iki tarafın çıkarlarını dengeler ve sürecin düzgün işlemesini sağlar.",
                },
                {
                    question: "Ceza Davalarında Avukatın Rolü Nedir?",
                    answer: "Ceza davalarında avukat, sanığın haklarını korur ve onu savunur. Davada, suçlamalarla ilgili delillerin değerlendirilmesi, savunma stratejilerinin belirlenmesi ve mahkeme sürecinin takibi avukatın sorumluluğundadır. Ceza davaları genellikle ciddi sonuçlar doğurabileceği için profesyonel bir avukatın desteği oldukça önemlidir.",
                },
                {
                    question: "Miras Hukukunda Avukata Ne Zaman Başvurulmalıdır?",
                    answer: "Miras hukuku, genellikle aile üyeleri arasında anlaşmazlıklara yol açabilir. Mirasın doğru bir şekilde paylaşılması, vasiyetname hazırlanması veya vergi yükümlülüklerinin yerine getirilmesi konusunda bir avukattan destek almak önemlidir. Avukat, mirasçılar arasında anlaşmazlık durumunda çözüm önerileri sunarak tarafları en adil şekilde yönlendirebilir.",
                },
                {
                    question: "Hukuki Danışmanlıkta Gizlilik Garantisi Var Mıdır?",
                    answer: "Evet, avukatlar müvekkillerinin gizliliğine büyük bir özen gösterir. Avukat-müvekkil ilişkisi, yasal olarak da gizlilik ilkesine dayanır. Bu, avukatınızla paylaştığınız her türlü bilginin yalnızca yasal temsiliniz amacıyla kullanılacağı anlamına gelir.",
                },
                {
                    question: "Bir Avukatla Ne Zaman İletişime Geçmeliyim?",
                    answer: "Hukuki bir sorun yaşadığınızda, sorun büyümeden önce profesyonel bir avukattan danışmanlık almanız önerilir. Hukuki süreçlerde zamanında alınacak adımlar, ileride büyük sorunların önüne geçebilir. İster iş, aile, miras veya ceza hukuku olsun, sorunun büyümeden bir avukatla görüşmek önemlidir.",
                },
                {
                    question: "Yabancı Bir Ülkede Hukuki Yardım Alabilir Miyim?",
                    answer: "Eğer yurtdışında bir hukuki sorunla karşılaşırsanız, o ülkenin hukuk sistemi hakkında bilgi sahibi bir avukata başvurmanız gerekebilir. Avukatımız, yerel hukukla ilgili bilgilere sahip olmasa bile, yurtdışında çalışan bir avukatla bağlantı kurmanızı sağlayarak size yol gösterebilir.",
                },
                {
                    question: "Hukuki Danışmanlık İçin Randevu Nasıl Alırım?",
                    answer: "Bizimle iletişime geçmek ve randevu almak için web sitemizdeki formu doldurabilir veya doğrudan telefonla ulaşabilirsiniz. Randevunuzun tarihini belirlemek ve hangi konuda yardımcı olabileceğimizi konuşmak için size en kısa sürede geri dönüş yapacağız.",
                },
            ],
        },
        // Contact
        contact: {
            title: "İletişim",
            description: "Avukat Veli Çelik, profesyonel destek için iletişime geçmeniz halinde size yardımcı olmaktan memnuniyet duyacaktır.",
            phone: "+09 531 667 60 24",
            email: "info@avvelicelik.com",
            address: "Karakoç Plaza 1/14 Yenişehir/Diyarbakır",
        },
        // Footer
        footer: {
            text: "2024 ErosBT. Tüm hakları saklıdır.",
        },
        danismanlikForm: {
            title: "Hukuk Danışmanlık Formu",
            categoryLabel: "Danışmanlık Kategorisi",
            subCategoryLabel: "Alt Kategori",
            descriptionLabel: "Durumunuzu Kısaca Açıklayın",
            nameLabel: "Ad Soyad",
            emailLabel: "E-posta",
            phoneLabel: "Telefon",
            appointmentLabel: "Randevu talep ediyorum",
            privacyAgreementLabel: "Gizlilik sözleşmesini kabul ediyorum",
            submitButton: "Gönder",
            errorMessage: "Mesaj gönderilemedi. Lütfen tekrar deneyin.",
            privacyAlert: "Lütfen gizlilik sözleşmesini onaylayın.",
            successModalTitle: "Mesaj Gönderildi",
            successModalDescription: "Mesajınız başarıyla avukata iletildi. Yardımcı olabilmemiz için yakında sizinle iletişime geçeceğiz.",
            modalCloseButton: "Tamam",
            categories: {
                "Aile Hukuku": ["Boşanma", "Nafaka Talebi", "Vesayet"],
                "Ticaret Hukuku": ["Sözleşme Hazırlama", "Şirket Kuruluşu", "Danışmanlık"],
                "Ceza Hukuku": ["Savunma", "Şikayet", "Tazminat"],
                "Miras Hukuku": ["Miras Paylaşımı", "Vasiyetname Hazırlama"],
            }
        },
        errorBoundary: {
            title: "Bir şeyler ters gitti.",
            message: "Lütfen daha sonra tekrar deneyin.",
        },
    },
    en: {
        // Navbar
        navbar: {
            home: "Home",
            about: "About",
            services: "Services",
            blog: "Blog",
            contact: "Contact",
            title: "Av. Veli ÇELİK",
        },
        // Herosection
        herosection: {
            title: "Welcome",
            description: "We pursue justice. Contact us now for professional legal advice.",
            button: "Make an Appointment",
        },
        // About
        about: {
            title: "About",
            description1: "Lawyer Veli Çelik is a legal professional recognized for his extensive experience and deep expertise in law.",
            description2: "He has proven himself through the experience he gained at Turkey's leading law firms and numerous successful case results.",
            description3: "Specializing in areas such as human rights, commercial law, and family law, Lawyer Veli Çelik always provides fair and effective legal representation to his clients.",
        },
        // Services
        services: {
            title: "Services",
            items: [
                {
                    title: "Human Rights Law",
                    description: "Striving for justice and protecting individual rights. Special focus on combating discrimination, freedom of expression, and wrongful detentions.",
                },
                {
                    title: "Commercial Law",
                    description: "Legal consultancy and resolution of commercial disputes. We provide services ranging from company establishment to contract preparation.",
                },
                {
                    title: "Family Law",
                    description: "Professional service in cases such as divorce, alimony, and custody. Offering solutions for complex situations like domestic violence and inheritance disputes.",
                },
                {
                    title: "Criminal Law",
                    description: "Defense in cases of criminal liability and protection of victims' rights. Advocating for justice while ensuring the rights of both the accused and victims.",
                },
                {
                    title: "Inheritance Law",
                    description: "Solutions for inheritance distribution, will preparation, and legal disputes. Providing expert advice for any inheritance-related conflicts.",
                },
            ],
        },
        // Blog
        blog: {
            title: "Blog",
            posts: [
                {
                    title: "Recent Changes: Criminal Law Reform",
                    description: "An evaluation of significant changes in criminal law in Turkey and their impact on individuals' rights.",
                },
                {
                    title: "5 Key Points in Divorce Cases",
                    description: "A guide to the fundamental aspects to consider in divorce cases and how to manage the process effectively.",
                },
                {
                    title: "Human Rights and Legal Protection",
                    description: "An examination of human rights violations and their legal dimensions.",
                },
            ],
        },
        // Affiliations
        affiliations: {
            title: "Organizations/Unions We Collaborate With",
            description: "Lawyer Veli Çelik is a member of the following social and environmental organizations and actively participates in their efforts:",
            items: [
                {
                    title: "Union of Turkish Bar Associations",
                    description: "One of the most important associations in the field of law.",
                },
                {
                    title: "Justice Platform",
                    description: "A community working to ensure and strengthen justice.",
                },
                {
                    title: "Environmental and Nature Association",
                    description: "Works on legislative and policy suggestions to protect the natural environment.",
                },
                {
                    title: "Women's Rights Platform",
                    description: "Focused on legal rights for women and gender equality.",
                },
            ],
        },
        // FAQ

        faq: {
            title: "Frequently Asked Questions (FAQ)",
            items: [
                {
                    question: "What Are Legal Consultancy Services?",
                    answer: "Legal consultancy involves a lawyer providing information about your legal rights, obligations, and procedures and guiding you as needed. This service can cover various legal fields such as divorce, commerce, inheritance, or criminal cases. Our lawyer offers the most suitable solutions for your situation, ensuring proper guidance.",
                },
                {
                    question: "When Do You Need a Lawyer in a Case?",
                    answer: "Many cases, especially those involving complex legal issues, require the support of a lawyer. A lawyer defends your rights during the case, prepares your legal documents, and represents you in court. Additionally, consulting with an expert lawyer in areas like property, family, commerce, or criminal law is crucial.",
                },
                {
                    question: "How Are Lawyer Fees Calculated?",
                    answer: "Lawyer fees depend on the type of case, the lawyer's experience, and the scope of the service. Most lawyers provide consultancy and representation services for a specific fee. Fees are usually determined as a flat fee, hourly rate, or success-based payment. It is recommended to have an initial meeting with the lawyer to understand the costs.",
                },
                {
                    question: "When Do You Need a Lawyer in Divorce Cases?",
                    answer: "The divorce process can be legally and emotionally complex. A professional lawyer can help you defend your rights and navigate the process healthily, especially on critical issues like property division and child custody. In divorce cases, the lawyer balances the interests of both parties and ensures the process runs smoothly.",
                },
                {
                    question: "What Is the Role of a Lawyer in Criminal Cases?",
                    answer: "In criminal cases, a lawyer protects and defends the rights of the accused. The lawyer evaluates evidence related to the charges, determines defense strategies, and oversees the court process. Since criminal cases often have serious consequences, the support of a professional lawyer is essential.",
                },
                {
                    question: "When Should You Consult a Lawyer in Inheritance Law?",
                    answer: "Inheritance law often leads to disputes among family members. Consulting a lawyer is important for properly distributing inheritance, preparing wills, or fulfilling tax obligations. In case of disagreements between heirs, the lawyer provides solutions and guides parties in the fairest way possible.",
                },
                {
                    question: "Is Confidentiality Guaranteed in Legal Consultancy?",
                    answer: "Yes, lawyers take great care to ensure client confidentiality. The lawyer-client relationship is legally based on the principle of confidentiality. This means that all information shared with your lawyer is used solely for your legal representation.",
                },
                {
                    question: "When Should You Contact a Lawyer?",
                    answer: "It is recommended to consult a professional lawyer as soon as you encounter a legal issue, before it escalates. Taking timely steps in legal processes can prevent major problems later. Whether it is business, family, inheritance, or criminal law, consulting a lawyer before the problem grows is important.",
                },
                {
                    question: "Can I Get Legal Assistance in a Foreign Country?",
                    answer: "If you encounter a legal issue abroad, you may need to consult a lawyer familiar with that country's legal system. While our lawyer may not have local legal knowledge, they can help you connect with a lawyer working abroad to guide you.",
                },
                {
                    question: "How Do I Schedule a Legal Consultancy Appointment?",
                    answer: "To contact us and schedule an appointment, you can fill out the form on our website or call us directly. We will get back to you as soon as possible to set the date for your appointment and discuss how we can assist you.",
                },
            ],
        },

        // Contact
        contact: {
            title: "Contact",
            description: "Lawyer Veli Çelik is pleased to assist you professionally if you get in touch.",
            phone: "+09 531 667 60 24",
            email: "info@avvelicelik.com",
            address: "Karakoç Plaza 1/14 Yenişehir/Diyarbakır",
        },
        // Footer
        footer: {
            text: "2024 ErosBT. All rights reserved.",
        },
        danismanlikForm: {
            title: "Legal Consultation Form",
            categoryLabel: "Consultation Category",
            subCategoryLabel: "Subcategory",
            descriptionLabel: "Briefly Describe Your Situation",
            nameLabel: "Full Name",
            emailLabel: "Email",
            phoneLabel: "Phone",
            appointmentLabel: "Request an Appointment",
            privacyAgreementLabel: "I agree to the privacy policy",
            submitButton: "Submit",
            errorMessage: "Message could not be sent. Please try again.",
            privacyAlert: "Please accept the privacy policy.",
            successModalTitle: "Message Sent",
            successModalDescription: "Your message has been successfully sent to the lawyer. We will contact you soon to assist you.",
            modalCloseButton: "Close",
            categories: {
                "Family Law": ["Divorce", "Alimony Request", "Guardianship"],
                "Commercial Law": ["Contract Preparation", "Company Formation", "Consulting"],
                "Criminal Law": ["Defense", "Complaint", "Compensation"],
                "Inheritance Law": ["Inheritance Distribution", "Will Preparation"],
            }
        }, errorBoundary: {
            title: "Something went wrong.",
            message: "Please try again later.",
        },
    },
    ku: {
        // Navbar
        navbar: {
            home: "Malpera Serê",
            about: "Li ser",
            services: "Xizmetên",
            blog: "Blog",
            contact: "Têkilî",
            title: "Av. Veli ÇELİK",
        },
        // Herosection
        herosection: {
            title: "Xêr hatî",
            description: "Em li ser dadgehê me û bi taybetî ser şerên mafên mirovê dimeşînin. Ji bo şîretên hukûkî profesyonel, bi me re têkilî bikin.",
            button: "Randevû bistînin",
        },
        // About
        about: {
            title: "Li ser",
            description1: "Avukata Veli Çelik, profesyonelê hukûkê ye ku bi tecrubeya mezin û zanîna derîn a li ser hukûkê tê nasîn.",
            description2: "Hûnerekê li ser ofîsên hukûkê yên herî nûjen û encamên serkeftî yên pirrjimar li ser şerên cuda dîtineke çêtir pêşkêş dike.",
            description3: "Veli Çelik, li ser mafên mirov, hukûka bazirganî, û hukûka malbatî bi zanîna derîn bûye û ji bo xizmetên hukûkî û temsîlê mafên adîl ji bo xwedî yên xizmetan.",
        },
        // Services
        services: {
            title: "Xizmetên",
            items: [
                {
                    title: "Hukûka Mafên Mirov",
                    description: "Serxwebûn û parastina mafên kesane. Bêbextî, azadîya axaftinê û hişkiriya bê şert li ser hevahîtiyên xurt li ser vê dawiyê.",
                },
                {
                    title: "Hukûka Bazirganî",
                    description: "Rêxistina bazirganî û çözûna şerên bazirganiyê. Ji bo we xizmetên herî pêşketin û hewcedariyên yên bi nava kontratê û çêkirina şîrketan têkevin.",
                },
                {
                    title: "Hukûka Malbatî",
                    description: "Xizmetê profesyonel li ser şerên wekar, nafîk, û pêwendiyan. Ji bo şerên zêdekirina miras û şêwazên xistinê mezin û şerên malbatî.",
                },
                {
                    title: "Hukûka Ceza",
                    description: "Parastina mafên we û difûn, xizmata xwe li ser şerên şûn û bi destûrê ve hilbijartinên pêşbirkên mafê parastinê.",
                },
                {
                    title: "Hukûka Miras",
                    description: "Çareseriya sharezên mirasê û wîlatîna şîrketan. Şandina xizmetên şîret û tevgerên zêde li ser şerên miras û sîstemên şehriyên.",
                },
            ],
        },
        // Blog
        blog: {
            title: "Blog",
            posts: [
                {
                    title: "Pêşveçûna Dawiyên: Reformeya Hukûka Ceza",
                    description: "Guhertoyên girîng di qanûna ceza de û awayên erênayên rêkûvaniya mezin li ser mafên mirovê.",
                },
                {
                    title: "5 Tiştên Pêwîst Li Ser Daneyên Boşanî",
                    description: "Daneyên bo boşanî û peywîstiyên ku di navberê wan de hewce ne û rêza şewq û bêtir zêdetir ku têgihiştin.",
                },
                {
                    title: "Mafên Mirov û Parastina Hukûkî",
                    description: "Têkildarîya mafên mirov û geranên mezin li ser dawiyên hilweşîna peymangî.",
                },
            ],
        },
        // Affiliations
        affiliations: {
            title: "Têkiliyên Lihevhatî",
            description: "Avukata Veli Çelik, li ser örgûhên siyasî û eslêvan têkiliyên çêtirîn bi dawiyên afişan dikin.",
            items: [
                {
                    title: "Yekîtiya Baroya Tirkî",
                    description: "Şîretên hukûkî yên herî şewitî",
                },
                {
                    title: "Platforma Dadgehê",
                    description: "Şîretên mezin yên li ser dadgehê bi weşa zorê ve têkilî bi hêvîyên wehîştinê şerên nû.",
                },
                {
                    title: "Çevreyê û Doğa Derneği",
                    description: "Şîrketên bi zorên dewletê têkilî bi ewlefîkên mafên we.",
                },
                {
                    title: "Platforma Mafên Jinê",
                    description: "Di nav hejmara mafên cinsiyetî û yên miyanî ya jin û erdî de, têkiliyên zêdekirina têkiliyên we kêm e.",
                },
            ],
        },

        // FAQ
        faq: {
            title: "Pirsên Pirr Rewşên (FAQ)",
            items: [
                {
                    question: "Xizmeta Şerîatê Çi Ye?",
                    answer: "Xizmeta şerîatê tê de avukat ji bo agahdariyê derbarê mafên we, berxwedanên we û prosedûrên şerîatê û rêberî dan dike. Ev xizmet dikare herêmên cihêreng yên şerîatê wekî dehûn, bazirganî, miras an jî dosyên kriminalî bi covered bike. Avukatê me herî zêde çözûneke cîhanî ya ku ji bo rewşa we ve baştir e, û rêberîya rastînê pêşkêş dike.",
                },
                {
                    question: "Kengê Divê Hûn Avukatê Bi Hev Re Bikin?",
                    answer: "Gelek dosyeyan, bi taybetî yên ku pirsgirêkên şerîatê ya kêm an jî giran heye, alîkariya avukateke pêwîst e. Avukat mafên we di nav şerîatê de parêz dike, belgeyên we ya şerîatî amadekirî dike û hûn di dadgehê de piştgirî dikin. Hema hema, alîkariya avukatên taybetî li herêmên mîras, mal û malper, bazirganî an jî şerîatê kriminalî girîng e.",
                },
                {
                    question: "Tu Çawa Mezinahiya Fîzanên Avukatan Hesibî Dike?",
                    answer: "Fîzanên avukatan di bin aliyên dosyê, tecrûbeya avukata û astengiyên xizmetê ye. Zêdetir avukatên pêşkêşî xizmetên şerîatî û wergêrtina dadgehê di fîzanek taybetî de pêşkêş dikin. Fîzanên bi gelemperî bi şêweyek nivîsî, bi sala saetê an jî bi bingehînî ya ser êrişê diyar dikin. Pêwîste ku we di destpêkê de bi avukatekê re hevrûdîtinê bike da ku fîzanên rast û rêxistinên xizmetê bi qasî bazarê were zanîn.",
                },
                {
                    question: "Kengê Divê Hûn Avukatekê Bi Hev Re Bikin Di Dosyên Dehûnê de?",
                    answer: "Pêvajoya dehûnê dikare bi hukm û histerîkê zêde pirr reş û giran bibe. Avukatê profesyonel dikare alîkariya we bike da ku mafên we parêz bike û pêvajoya we bi sahtî ve bistîne, bi taybetî li ser mihên girîng yên wekî parastina mal û xwedîtiya zarokan. Di dosyên dehûnê de, avukat cihê mafên her du aliyên parêzê dike û pêvajoya we bi fermandekî sahtî dijîne.",
                },
                {
                    question: "Rolê Avukatê Di Dosyên Kriminalî de Çi Ye?",
                    answer: "Di dosyên kriminalî de, avukat mafên parêzvanê têkiliyê têgihiştî û parêzî dike. Avukat nîşanên girêdayî di dosyên girîngan de şîrove dike, stratejîyên parêzvanê diyar dike û pêvajoya dadgehê alîkarî dike. Ji ber ku dosyên kriminalî gelek caran piştrastî li ser encamên giran in, alîkariya avukatekê profesyonel hewcedar e.",
                },
                {
                    question: "Kengê Divê Hûn Bi Avukatekê Re Bikin Di Mirasê de?",
                    answer: "Mîrasê gelek caran bi pirsgirêkan di nav malbatê de reş dibe. Bi avukatê re hevrûdîtinê zêde girîng e da ku mîrasa we rast bikaribe, herêmên mîrasê bi rastî parve bibe, an jî serbestîên bacan pêşkeş bike. Heke tevlîbûnên di nav mirasdarên de hebin, avukat hinekî çarenûs pêşkêş dike û aliyên di her şêwayekê yên herî adaletî de rêberî dike.",
                },
                {
                    question: "Ma Sînorên Kîşk û Pîşesazî Di Şerîatê de Jî Derbas Dikin?",
                    answer: "Erê, avukatên we zêde bi cihê mafên xweserî şîfre hildibin. Têgihiştin û neçeliyên we di bin hukmê mafê şîfreyê de mayînin. Ev wateya ku hemî agahdariyên ku we bi avukatê re parve dikin tenê bo şerîatê pêşkêşkirinê têne bikar anîn.",
                },
                {
                    question: "Kengê Divê Hûn Bi Avukatekê Re Bikin?",
                    answer: "Pêwîste ku hûn bi avukatê profesyonel re hevrûdîtinê bikin, her çend pirsgirêkên şerîatî tunebûn. Hûn dîtinên rêxistinên we ya şerîatî zêde li ser xwerûdîtin û bi baştirîn awayê pêvajoya xwe bi dawî bikin. Her wiha, dema pirsgirêkê bi pirr nerm bibe, bi avukatê re şîroveyê kirin pêwîste.",
                },
                {
                    question: "Ma Dikaremin Alîkariya Şerîatî Li Dewletê Dî de Bigihîjim?",
                    answer: "Heke hûn bi pirsgirêkê şerîatî di welatê din de reşbûn, pêwîst e ku bi avukatêkî ku ji sistema şerîatê ya wî welatî agahdar e, rewendî bikin. Hema hema, avukatê me tu agahdariyên şerîatî ya herêmê di van welatên de nezanîbûn, lê belê dikare hûn bi avukatê ku di welatên din de kar dike hevrûdîtinê bike û hûn dikarin pêşkêşî lêkolînanê bikin.",
                },
                {
                    question: "Çawa Randevûyê Bi Avukatê Bikin?",
                    answer: "Ji bo têkilî kirin û randevû da ku bikin, hûn dikarin fôrma li malperê me têkildar bikin an jî bi telefonê me re têkilî bikin. Em bi hêvî ji we re bi sererastî vegerînin da ku randevûya xwe çêbikin û rêyên ku dikarin alîkariya şerîatî bi we re pêşkêş bikin.",
                },
            ],
        },

        // Contact
        contact: {
            title: "Têkilî",
            description: "Avukat Veli Çelik hez dike ku hûn bi şêwazê profesyonel alîkariya me bigihîjin.",
            phone: "+09 531 667 60 24",
            email: "info@avvelicelik.com",
            address: "Karakoç Plaza 1/14 Yenişehir/Diyarbakır",
        },

        // Footer
        footer: {
            text: "2024 ErosBT. Hemû mafên parastin.",

        }, errorBoundary: {
            title: "Tiştek şaş çû.",
            message: "Ji kerema xwe re paşê dîsa biceribînin.",
        }
    },

    zz: {

        // Navbar
        navbar: {
            home: "Serî",
            about: "Li ser",
            services: "Xizmetên",
            blog: "Blog",
            contact: "Têkilî",
            title: "Av. Veli ÇELİK",
        },
        // Herosection
        herosection: {
            title: "Bêxêr hatî",
            description: "Em di peyda kirina adaletê de ne. Ji bo şîretê yên profesyonel ya hukûkî bi me re têkilî bikin.",
            button: "Randevû bistînin",
        },
        // About
        about: {
            title: "Li ser",
            description1: "Peywendiyê Veli Çelik, bi pêşeroj û baldarîyên xwe di qada hukûkê de tê nasîn.",
            description2: "Di ofîsanên hukûkî yên herêmî yên herî serbaz ên Tirkîyeyê de bihîstin û bi encamên serketî yên dîyar kirî ye.",
            description3: "Peywendiyê Veli Çelik, ji bo şehidan li aliyên hukûka mirovî, hukûka bazirganî û hukûka malbata gelekî zanyarî û taybetmendî heye.",
        },
        // Services
        services: {
            title: "Xizmetên",
            items: [
                {
                    title: "Hukûka Mirovî",
                    description: "Bi tevgerên adaletê û parastina mafên kesane. Lêkolînên bi taybetî li ser cihên neteweyî, azadiya axaftinê û şîkesta şewatî.",
                },
                {
                    title: "Hukûka Bazirganî",
                    description: "Rêbertiya hukûkî û çêkirina çêbûnan bazirganî. Ji çêkirina pargîdaniyan heta afirandina peymanan xizmetan pêşkêş dikin.",
                },
                {
                    title: "Hukûka Malbata",
                    description: "Xizmetên profesyonel di heman dema da, ji bo radestkirina mal, nafaka û koçbera zarok. Em alîkarî dikim li peyama şewatî, pêşniyazên miras û parastina mafên malbata.",
                },
                {
                    title: "Hukûka Ceza",
                    description: "Parastina mafên kêmbûn û şêwirmendiyên xwedî û parastina mafên victiman. Di derbarê devokê dikevin û hûnermendî û parastina mafên abir, şîretê dikevin.",
                },
                {
                    title: "Hukûka Miras",
                    description: "Çarçoveyên peymanan miras û pêşkêşiyên komêkî. Xizmetên bi rêyî em alîkarî li çend şîretên miras û pêşniyazên xizmetên.",
                },
            ],
        },
        // Blog
        blog: {
            title: "Blog",
            posts: [
                {
                    title: "Guhertoyên Nû: Reformên Hukûka Ceza",
                    description: "Guhertoyên sereke yên li hukûka ceza yên Tirkîyeyê û wêrahîyên wan li ser mafên kesane.",
                },
                {
                    title: "5 Pûntên Girîng Li Dava Boşanê",
                    description: "Di dawiya boşanê de tiştekî mezin li ser wan xizmetên girîng û şîretê yên çûnên nivînan.",
                },
                {
                    title: "Mafên Mirovî û Parastina Hukûkî",
                    description: "Di heman dema da hukûkên mirad û xizmetên negerîn yên mîna zeman û li ser nermaltiyekiyan.",
                },
            ],
        },
        // Affiliations
        affiliations: {
            title: "Lîsteyên Hemûbûnî/Endamên Me",
            description: "Peywendiyê Veli Çelik bi yekê li ser xizmetên hemûbûnîyên jêhatî û kesane ku ewliya xizmeta.",
            items: [
                {
                    title: "Komeleya Baroya Tirkî",
                    description: "Yek ji mebestên herî mezin di qanûnan herêmi de.",
                },
                {
                    title: "Platforma Adaletê",
                    description: "Koma heywanên tenê îsalî ku şîretê adaletî.",
                },
                {
                    title: "Komeleya Parastina Çewre",
                    description: "Xizmatên parastina qazîn û polîtîkayên dilî bîngozên zû.",
                },
                {
                    title: "Platforma Mafên Jinên",
                    description: "Di qada mafên jinên û riya eşîtiya cinsî de.",
                },
            ],
        },

        faq: {
            title: "Pirsên Pirrêzên (FAQ)",
            items: [
                {
                    question: "Xizmeta Danûstandina Yasaî Çi Ye?",
                    answer: "Xizmeta danûstandina yasaî di nav de pirsgirêkên yasaî yên we, berpirsyarî û proseduran de diyarî dike û di hewceyekî de we re rêbendan pêşniyar dike. Ev xizmet dikare di cîhêyên yasaî yên cuda yên wekî derbasî, bazirganî, miras û doza kriminalî de girêdayî be. Avukatê me ji bo rewşê we şîroveyên herî baş pêşkêş dike û rêbendanê rastîn pêşkêş dike.",
                },
                {
                    question: "Kengê divê hûn avukatê bi hewceyê doza xwe bibînin?",
                    answer: "Pir dozan, bi taybetî yên ku têkiliyên yasaîyên giran heye, hewce ne ku alîkariya avukata pêşkêş bikin. Avukat mafên we di doza de parastin dike, belgenameyên yasaî amade dike û hûn di dadgehê de nîşan dide. Di heman demê de, pêwîst e ku bi avukatekê profesyonel li ser mal, malperê, bazirganî an jî doza kriminalî şîroveyekê biceribînin.",
                },
                {
                    question: "Çawa pereya avukata tê hesibandin?",
                    answer: "Pereya avukata li ser cinsê doza, têcrubeya avukatê û pîvandanê xizmetê tê hesibandin. Gelek avukatên xizmet û parastina hukûkî pêşkêş dikin bi pereyek taybet. Pereyê tê hesibandin bi destûra perçekê, hejmareke saetanî an jî li ser serkeftinê. Pêşniyar tê ku hûn bi avukata xwe serdana destpêkê bikin da ku hûn baştir fêm bikin çi qas pereyê têne pêşkêş kirin.",
                },
                {
                    question: "Kengê divê hûn avukatê bi hewceyê doza derbasî bibînin?",
                    answer: "Prosesê derbasî dikare bi qanûn û hisyarî bi kêşekê mezintir be. Avukatê profesyonel dikare hûnê parastin bikin û prosesê bi şewqê bi rêk û weyîr ve pêşkêş bikin, bi taybetî li ser pêdivîyên giring ên wekî pargîdanê mal û parastina zarok. Di doza derbasî de, avukat balansa alîkariya her du alîyan dike û di vê prosesê de rêkê xweş dike.",
                },
                {
                    question: "Çi rolê avukatê li dozan kriminalî de ye?",
                    answer: "Di dozan kriminalî de, avukat mafên yê têkiliyê li benda xwe parastin û şer dike. Avukat belgenameyên têkiliyê yên girêdayî doza ve nêrînî dike, stratejîyên parastinê têne diyarî kirin û pêşniyarên dadgehê êdî kontrol dike. Hinek dozan kriminalî pir rûmetê giran dikarin, ji ber vê yekê alîkariya avukata profesyonel pir girîng e.",
                },
                {
                    question: "Kengê divê hûn avukatê bi hewceyê yasaî mirasî bibînin?",
                    answer: "Yasaî miras gelek caran dikare cihê riya şer û têkiliyên malbatî bibe. Xwestinê avukatê bi awayê rast ji bo parastina mirasê, amade kirina wasîyet û beşdarîyên bacê pêşniyar dike. Di reşandina wekî têkiliyên mirasê de, avukat çarêbêrî û rêbendanên zimanî û cîhanî pêşkêş dike.",
                },
                {
                    question: "Ma em dikarin bi danûstandina yasaî ya şexsî bihêlin?",
                    answer: "Erê, avukat pirrî rûmeta girtîbûnê bi we parastin dikin. Têkiliyê avukat-şexsî pêk tê li ser bingehên qanûnî, ku her tişt ku hûn bi avukata xwe re parve dikin tenê ji bo parastina yasaî ya we hatîye bikar anîn.",
                },
                {
                    question: "Kengê divê hûn avukatê bi hewceyê parastina hukûkî de peywendî bikin?",
                    answer: "Pêşniyar e ku hûn her ku di qasî pirsgirêkên yasaî de hatiye, bi avukata profesyonel re berê bikaribin. Guhertina qanûnî dikeve nav doza we, hûn dikarin bilindbûna pirsgirêkê pêş bikin û hûn baştir li ser hînbûna pargîdanê hukûkî re pêşniyar dikin.",
                },
                {
                    question: "Ma ez dikarim alîkariya yasaî ji welatekê din bistînim?",
                    answer: "Heke hûn di welatekê din de bi pirsgirêkên yasaî re têkiliyê li hev bikin, hûn hewce ne ku bi avukatê ku li ser pergalên qanûnî yên wê welatî jî bibînin. Di heman demê de, her çend avukatê me zanînên lokal yên qanûnî hene, ew dikare alîkarî we bike da ku hûn bi avukatên ku li welatê din dixebitin bi hev re rabîn.",
                },
                {
                    question: "Çawa dikarim randevûyek danûstendina yasaî saz bikin?",
                    answer: "Ji bo têkiliyê û saz kirina randevûyê, hûn dikarin fermanê li malperê me biguherînin an jî bi telefonê rastî bi me re peywendî bikin. Me ê bi demekî zû ji we re vegerê û saz kirina randevû û alîkariyê bikarîbîne.",
                },
            ],
        },

        // Contact
        contact: {
            title: "Têkilî",
            description: "Peywendiyê Veli Çelik heştê serkêftî de peydakirina xizmetê şîretêya xwedîyê ye.",
            phone: "+09 531 667 60 24",
            email: "info@avvelicelik.com",
            address: "Karakoç Plaza 1/14 Yenişehir/Diyarbakır",
        },
        // Footer
        footer: {
            text: "2024 ErosBT. Hemû mafên parastin.",
        }, errorBoundary: {
            title: "Tiştek şaş çû.",
            message: "Ji kerema xwe re paşê dîsa biceribînin.",
        }
    }
};

export default translations;
