import React from 'react';
import './About.css';
import profil from '../assets/profil.png'; // Ensure the correct path to the image
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const About = () => {
    const { language } = useLanguage(); // Get the current language from context

    // Retrieve the translated content based on selected language
    const translation = translations[language].about;

    return (
        <section id="about" className='about'>
            <h2 className="about-title">{translation.title}</h2>
            <div className="about-content">
                {/* Image Section */}
                <div className="about-image">
                    <img src={profil} alt="Avukat" />
                </div>

                {/* Text Section */}
                <div className="about-text">
                    <p>
                        {translation.description1}
                        <strong> {translation.description2} </strong>
                        {translation.description3}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default About;
