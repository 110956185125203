import React, { createContext, useState, useContext } from 'react';

// Dil seçimi için Context oluşturma
const LanguageContext = createContext();

// Context'ten dil bilgisini almak için hook
export const useLanguage = () => useContext(LanguageContext);

// Dil provider'ı oluşturma
export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('tr'); // Varsayılan dil Türkçe

    const changeLanguage = (lang) => setLanguage(lang); // Dil değişim fonksiyonu

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
