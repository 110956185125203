import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './Contact.css';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const Contact = () => {
    const { language } = useLanguage(); // Aktif dil bilgisini alıyoruz
    const translation = translations[language].contact; // Aktif dilin contact içeriğini alıyoruz

    return (
        <section id="contact" className="contact">
            <h2 className="contact-title">{translation.title}</h2>
            <p>{translation.description}</p>
            <div className="contact-info">
                <div className="contact-item">
                    <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                    <a href={`tel:${translation.phone}`} className="contact-text">
                        {translation.phone}
                    </a>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                    <a href={`mailto:${translation.email}`} className="contact-text">
                        {translation.email}
                    </a>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
                    <a
                        href={`https://www.google.com/maps?q=${encodeURIComponent(translation.address)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-text"
                    >
                        {translation.address}
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Contact;
