import React, { useState } from 'react';
import './FAQ.css';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const { language } = useLanguage(); // Get the active languages
    const faqData = translations[language].faq.items; // Dynamically load FAQ data based on the current language

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <h2>{translations[language].faq.title}</h2>
            {faqData.map((faq, index) => (
                <div className="faq-item" key={index}>
                    <div className="faq-question" onClick={() => toggleAccordion(index)}>
                        <h3>{faq.question}</h3>
                        <span>{activeIndex === index ? '-' : '+'}</span>
                    </div>
                    {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
                </div>
            ))}
        </section>
    );
};

export default FAQ;
