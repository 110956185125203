import React from 'react';
import { useLanguage } from '../language/LanguageContext';  // Import LanguageContext
import translations from '../language/lang';  // Import translations

const NotFound = () => {
    const { currentLanguage } = useLanguage();  // Get current language from context

    // Get NotFound page translations based on current language
    const notFoundData = translations[currentLanguage].notFound;

    return (
        <div className="not-found">
            <h2>{notFoundData.title}</h2>
            <p>{notFoundData.message}</p>
        </div>
    );
};

export default NotFound;
