import React, { useState } from "react";
import "./Herosection.css";
import { Modal, Box, Button } from "@mui/material";
import DanismanlikForm from "./DanismanlikForm";
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const HeroSection = () => {
    const [open, setOpen] = useState(false);
    const { language } = useLanguage(); // Get the current language from context

    // Retrieve the translated content based on selected language
    const heroSectionData = translations[language].herosection;


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <section className={`hero-section ${open ? "blur-background" : ""}`}>
            <div className="hero-content">
                <h1>{heroSectionData.title}</h1>
                <p>{heroSectionData.description}</p>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleOpen}
                    className="hero-button"
                    aria-label="Randevu Al"
                >
                    {heroSectionData.button}
                </Button>
            </div>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="danismanlik-form-title"
                aria-describedby="danismanlik-form-description"
            >
                <Box className="modal-box">
                    <DanismanlikForm onClose={handleClose} />
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleClose}
                        sx={{ mt: 2 }}
                        fullWidth
                    >
                        Kapat
                    </Button>
                </Box>
            </Modal>
        </section>
    );
};

export default HeroSection;
