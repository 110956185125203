import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/amblem.png';
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { language } = useLanguage(); // Get the current language from context

    const toggleMenu = () => setIsOpen(!isOpen);

    // Retrieve the translated content based on selected language
    const translation = translations[language].navbar;

    return (
        <header className="navbar">
            {/* Logo ve kullanıcı alanları */}
            <div className="navbar-logo">
                <Link to="/">
                    <img src={logo} alt="Logo" className="logo-img" />
                </Link>
            </div>

            <div className="navbar-user">
                <Link to="/" className="navbar-user-link">{translation.title}</Link>
            </div>

            {/* Hamburger Menü */}
            <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            {/* Menü Linkleri */}
            <nav className={`navbar-links ${isOpen ? 'show-menu' : ''}`}>
                <a href="#about" className="navbar-link">{translation.about}</a>
                <a href="#services" className="navbar-link">{translation.services}</a>
                <a href="#blog" className="navbar-link">{translation.blog}</a>
                <a href="#contact" className="navbar-link">{translation.contact}</a>
            </nav>
        </header>
    );
};

export default Navbar;