// src/components/ErrorBoundary.js
import React, { Component } from 'react';
import './ErrorBoundary.css'; // Import the new CSS for styling
import { useLanguage } from '../language/LanguageContext'; // Import the useLanguage hook
import translations from '../language/lang'

// ErrorBoundary component as a functional component
const ErrorBoundaryFunctional = (props) => {
    const { language } = useLanguage(); // Get the active language
    const translation = translations[language].errorBoundary; // Get the error messages in the active language

    if (props.hasError) {
        return (
            <div className="error-boundary">
                <h2>{translation.title}</h2>
                <p>{translation.message}</p>
            </div>
        );
    }

    return props.children;
};

// ErrorBoundary class component
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error("Error:", error, info);
    }

    render() {
        return (
            <ErrorBoundaryFunctional hasError={this.state.hasError}>
                {this.props.children}
            </ErrorBoundaryFunctional>
        );
    }
}

export default ErrorBoundary;
