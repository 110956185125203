import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import { LanguageProvider } from './language/LanguageContext'; // LanguageProvider'ı import et

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* LanguageProvider ile sarıyoruz */}
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);

// Web vitals raporlama (isteğe bağlı)
reportWebVitals();
