import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './components/NotFound'; // Optional: a 404 page
import './App.css';
import ErrorBoundary from './components/ErrorBoundary';
import GoToTop from './components/GoToTop';

const Layout = ({ children }) => (
  <>
    <Navbar />
    <main>{children}</main>
    <Footer />
    <GoToTop /> {/* Add GoToTop component here */}
  </>
);

const App = () => (
  <Router future={{ v7_relativeSplatPath: true }}>
    <ErrorBoundary>
      <div className="App">
        <Layout>
          <Routes>
            {/* Home sayfasını ana rotada tanımlıyoruz */}
            <Route path="/" element={<Home />} />
            {/* Tanımlanmayan bir sayfa için 404 sayfası */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </div>
    </ErrorBoundary>
  </Router>
);

export default App;
